<template>
  <v-container>
    <v-navigation-drawer permanent expand-on-hover app clipped>
      <v-list nav dense>
        <v-dialog
          v-model="editTextDialog"
          hide-overlay
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>
                  mdi-comment-edit
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit Text</v-list-item-title>
            </v-list-item>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="editTextDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Edit Text</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="editTextDialog = false">
                  Apply Changes
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <!-- <v-list three-line subheader>
                              <v-subheader>User Controls</v-subheader>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-list-item-title
                                          >Content filtering</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                          >Set the content filtering level to
                                          restrict apps that can be
                                          downloaded</v-list-item-subtitle
                                      >
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-list-item-title
                                          >Password</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                          >Require password for purchase or use
                                          password to restrict
                                          purchase</v-list-item-subtitle
                                      >
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list three-line subheader>
                              <v-subheader>General</v-subheader>
                              <v-list-item>
                                  <v-list-item-action>
                                      <v-checkbox
                                          v-model="notifications"
                                      ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title
                                          >Notifications</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                          >Notify me about updates to apps or
                                          games that I
                                          downloaded</v-list-item-subtitle
                                      >
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-action>
                                      <v-checkbox v-model="sound"></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>Sound</v-list-item-title>
                                      <v-list-item-subtitle
                                          >Auto-update apps at any time. Data
                                          charges may apply</v-list-item-subtitle
                                      >
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-action>
                                      <v-checkbox v-model="widgets"></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title
                                          >Auto-add widgets</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                          >Automatically add home screen
                                          widgets</v-list-item-subtitle
                                      >
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list> -->
            <v-container>
              <v-row class="mt-3">
                <v-text-field
                  v-model="sampleText"
                  label="Sample Text"
                  placeholder="Enter your sample text here"
                  outlined
                ></v-text-field>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Size</p>
                  <v-row class="ma-0">
                    <v-col class="px-0 pt-0" cols="4">
                      <v-text-field
                        v-model="fontSizeMeasure"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="fontSizeUnit"
                        :items="cssUnits"
                        label="Size Unit"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Weight (bold=700, normal=400)</p>
                  <v-overflow-btn
                    v-model="fontWeight"
                    :items="fontWeights"
                    label="Weight"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Style</p>
                  <v-overflow-btn
                    v-model="fontStyle"
                    :items="fontStyles"
                    label="Style"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Decoration Line</p>
                  <v-overflow-btn
                    v-model="textDecorationLine"
                    :items="textDecorationLines"
                    label="Decoration Line"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Text Transform</p>
                  <v-overflow-btn
                    v-model="textTransform"
                    :items="textTransforms"
                    label="Text Transform"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Variant Cap</p>
                  <v-overflow-btn
                    v-model="fontVariantCap"
                    :items="fontVariantCaps"
                    label="Variant Cap"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Font Stretch</p>
                  <v-overflow-btn
                    v-model="fontStretch"
                    :items="fontStretches"
                    label="Font Stretch"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Letter Spacing</p>
                  <v-row class="ma-0">
                    <v-col class="px-0 pt-0" cols="4">
                      <v-text-field
                        v-model="letterSpacing"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="letterSpacingUnit"
                        :items="cssUnits"
                        label="Letter Spacing Unit"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Word Spacing</p>
                  <v-row class="ma-0">
                    <v-col class="px-0 pt-0" cols="4">
                      <v-text-field
                        v-model="wordSpacing"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="wordSpacingUnit"
                        :items="cssUnits"
                        label="Word Spacing Unit"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Color</p>
                  <v-color-picker v-model="color"></v-color-picker>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Background Color</p>
                  <v-color-picker v-model="backgroundColor"></v-color-picker>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Shadow</p>
                  <v-row class="ma-0">
                    <v-col cols="12" class="pa-0">
                      <p class="ma-0 caption">
                        Width
                      </p>
                    </v-col>
                    <v-col class="px-0 py-0" cols="4">
                      <v-text-field
                        v-model="textShadowWidth"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="textShadowWidthUnit"
                        :items="cssUnits"
                        label="Text Shadow Width"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col cols="12" class="pa-0">
                      <p class="ma-0 caption">
                        Height
                      </p>
                    </v-col>
                    <v-col class="px-0 py-0" cols="4">
                      <v-text-field
                        v-model="textShadowHeight"
                        type="number"
                        outlined
                        class="mt-0"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="textShadowHeightUnit"
                        :items="cssUnits"
                        label="Text Shadow Height"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col cols="12" class="pa-0">
                      <p class="ma-0 caption">
                        Blur
                      </p>
                    </v-col>
                    <v-col class="px-0 py-0" cols="4">
                      <v-text-field
                        v-model="textShadowBlur"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-overflow-btn
                        v-model="textShadowBlurUnit"
                        :items="cssUnits"
                        label="Text Shadow Blur"
                        class="ma-0"
                      ></v-overflow-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <p>Shadow Color</p>
                  <v-color-picker v-model="textShadowColor"></v-color-picker>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="filterFontDialog"
          hide-overlay
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>
                  mdi-filter
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Filter Fonts</v-list-item-title>
            </v-list-item>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="filterFontDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Filter Fonts</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-autocomplete
                v-model="fontSearchFilters"
                auto-select-first
                clearable
                deletable-chips
                dense
                filled
                multiple
                rounded
                small-chips
                :items="allFonts"
                label="Search Fonts"
                hide-details
              ></v-autocomplete>
              <v-btn class="mt-3" @click="removeFilters">
                <v-icon dense x-small>mdi-filter-minus</v-icon>
                <span class="ml-2">Remove Filters</span>
              </v-btn>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- <v-dialog
          v-model="uploadFontDialog"
          hide-overlay
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>
                  mdi-file-upload
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Upload Fonts</v-list-item-title>
            </v-list-item>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="uploadFontDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Upload Fonts</v-toolbar-title>
            </v-toolbar>
            <template>
              <div>
                <v-file-input
                  :accept="acceptedFileExtensions"
                  label="File input"
                  multiple
                  @change="uploadFonts"
                ></v-file-input>
              </div>
            </template>
          </v-card>
        </v-dialog>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>
              mdi-tag
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tag Fonts</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>
              mdi-tournament
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Decision Tree</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Save</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
    <div
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [0, 0.5, 1.0]
        }
      }"
    ></div>
    <v-item-group multiple>
      <v-row v-if="sampleText != ''">
        <v-col v-for="(font, fontIndex) in displayedFonts" :key="fontIndex">
          <v-item v-slot:default="{ active, toggle }">
            <v-card min-width="220" class="text-no-wrap" @click="toggle">
              <v-row class="ma-0">
                <!-- <v-card-subtitle class="pl-2 pt-2"> -->
                <p class="text-body-2 pl-2 pt-2">
                  <a
                    :href="
                      'https://fonts.google.com/specimen/' +
                        font.replace(/ /g, '+')
                    "
                    style="text-decoration: none; color: gray; font-weight: 300;"
                    target="_blank"
                    >{{ font }}</a
                  >
                </p>

                <v-spacer></v-spacer>
                <v-btn icon top right>
                  <v-icon>{{
                    active
                      ? "mdi-checkbox-marked-circle"
                      : "mdi-checkbox-blank-circle-outline"
                  }}</v-icon>
                </v-btn>
              </v-row>
              <p
                :style="{
                  'font-family': font,
                  'font-weight': fontWeight,
                  'font-style': fontStyle,
                  'text-decoration-line': textDecorationLine,
                  'text-transform': textTransform,
                  'font-variant-caps': fontVariantCap,
                  'font-size': fontSizeMeasure + fontSizeUnit,
                  'font-stretch': fontStretch,
                  'letter-spacing': letterSpacing + letterSpacingUnit,
                  'word-spacing': wordSpacing + wordSpacingUnit,
                  color: color,
                  'background-color': backgroundColor,
                  'text-shadow':
                    textShadowWidth +
                    textShadowWidthUnit +
                    ' ' +
                    textShadowHeight +
                    textShadowHeightUnit +
                    ' ' +
                    textShadowBlur +
                    textShadowBlurUnit +
                    ' ' +
                    textShadowColor
                }"
                class="px-3 pb-3"
              >
                {{ sampleText }}
              </p>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <v-fab-transition>
      <v-btn
        v-show="isIntersecting"
        color="primary"
        small
        bottom
        right
        fab
        fixed
        @click="
          $vuetify.goTo(0, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic'
          })
        "
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
// import _ from "lodash"
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      sampleText: "Sample Text",
      fontWeight: 400,
      fontStyle: "normal",
      textDecorationLine: "none",
      textTransform: "none",
      fontVariantCap: "normal",
      fontSizeMeasure: 12,
      fontSizeUnit: "pt",
      letterSpacing: 0,
      letterSpacingUnit: "pt",
      wordSpacing: 0,
      wordSpacingUnit: "pt",
      fontStretch: "normal",
      color: "#000000",
      backgroundColor: "#FFFFFF",
      textShadowHeight: 0,
      textShadowHeightUnit: "pt",
      textShadowWidth: 0,
      textShadowWidthUnit: "pt",
      textShadowBlur: 0,
      textShadowBlurUnit: "pt",
      textShadowColor: "#000000",
      fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900], // 400=normal, 700=bold
      fontStyles: ["normal", "italic", "oblique"],
      textDecorationLines: ["none", "underline", "overline", "line-through"],
      textTransforms: ["none", "capitalize", "uppercase", "lowercase"],
      fontVariantCaps: [
        "normal",
        "small-caps",
        "all-small-caps",
        "petite-caps",
        "all-petite-caps",
        "unicase",
        "titling-caps"
      ],
      cssUnits: ["cm", "mm", "in", "px", "pt", "pc"],
      fontStretches: [
        "ultra-condensed",
        "extra-condensed",
        "condensed",
        "semi-condensed",
        "normal",
        "semi-expanded",
        "expanded",
        "extra-expanded",
        "ultra-expanded"
      ],
      googleFonts: [
        "Lato",
        "Open Sans",
        "Roboto",
        "Noto Sans JP",
        "Source Sans Pro",
        "Roboto Condensed",
        "Montserrat",
        "Raleway",
        "Nanum Gothic",
        "Lora",
        "Noto Sans TC",
        "Oswald",
        "Dosis",
        "Fira Sans",
        "Poppins",
        "Hind Siliguri",
        "Slabo 27px",
        "Hind",
        "Quicksand",
        "Roboto Mono",
        "Barlow",
        "Barlow Condensed",
        "Noto Sans",
        "Source Code Pro",
        "PT Sans Narrow",
        "Assistant",
        "Libre Franklin",
        "Noto Sans SC",
        "PT Sans",
        "Cormorant Garamond",
        "Teko",
        "Ubuntu",
        "Abel",
        "Roboto Slab",
        "Staatliches",
        "Fredoka One",
        "Merriweather",
        "Varela Round",
        "Exo",
        "Playfair Display",
        "Source Serif Pro",
        "Comfortaa",
        "Nunito",
        "Patrick Hand",
        "Open Sans Condensed",
        "Shadows Into Light",
        "Kanit",
        "Patua One",
        "Hind Madurai",
        "Maven Pro",
        "Mulish",
        "Noto Sans KR",
        "Acme",
        "Rubik",
        "Spartan",
        "Lobster Two",
        "PT Serif",
        "Play",
        "Chakra Petch",
        "IBM Plex Serif",
        "Mukta",
        "Fira Sans Condensed",
        "Kalam",
        "Domine",
        "Sacramento",
        "Bree Serif",
        "Archivo",
        "Noto Serif",
        "Nunito Sans",
        "PT Sans Caption",
        "Sanchez",
        "Work Sans",
        "Cinzel",
        "Sawarabi Mincho",
        "Titillium Web",
        "Vidaloka",
        "Vollkorn",
        "Hammersmith One",
        "Barlow Semi Condensed",
        "Cabin Condensed",
        "Alegreya Sans",
        "Inconsolata",
        "Red Hat Display",
        "Almarai",
        "Heebo",
        "Great Vibes",
        "Oxygen",
        "Inter",
        "Noticia Text",
        "Antic",
        "Parisienne",
        "Quattrocento Sans",
        "Encode Sans",
        "Josefin Sans",
        "Karla",
        "Arimo",
        "Bungee",
        "Secular One",
        "Anton",
        "Arapey",
        "Handlee",
        "Crimson Text",
        "Luckiest Guy",
        "Basic",
        "Bitter",
        "Cookie",
        "Annie Use Your Telescope",
        "IBM Plex Sans",
        "Special Elite",
        "Black Han Sans",
        "Istok Web",
        "Bubblegum Sans",
        "Dancing Script",
        "Hind Vadodara",
        "Bangers",
        "Spinnaker",
        "Economica",
        "Trirong",
        "DM Serif Display",
        "Mallanna",
        "Fjalla One",
        "Pragati Narrow",
        "VT323",
        "Libre Baskerville",
        "Mukta Malar",
        "Taviraj",
        "Cabin",
        "Ruda",
        "Actor",
        "Bebas Neue",
        "Crimson Pro",
        "Architects Daughter",
        "Lusitana",
        "Indie Flower",
        "Julius Sans One",
        "Charm",
        "Marck Script",
        "Yanone Kaffeesatz",
        "Aleo",
        "Zilla Slab",
        "Arvo",
        "Gentium Book Basic",
        "Lobster",
        "Pacifico",
        "Playball",
        "Cairo",
        "Fugaz One",
        "Antic Slab",
        "Poly",
        "Varela",
        "Exo 2",
        "Sorts Mill Goudy",
        "Balsamiq Sans",
        "Black Ops One",
        "Coustard",
        "Merriweather Sans",
        "Aclonica",
        "Noto Sans HK",
        "Overpass",
        "Damion",
        "EB Garamond",
        "Knewave",
        "Pridi",
        "Sunflower",
        "Covered By Your Grace",
        "Goudy Bookletter 1911",
        "Coda",
        "Averia Libre",
        "Pinyon Script",
        "Battambang",
        "PT Serif Caption",
        "Ceviche One",
        "Martel",
        "Sriracha",
        "Lalezar",
        "Mirza",
        "Permanent Marker",
        "Catamaran",
        "Dawning of a New Day",
        "Schoolbell",
        "Amatic SC",
        "Mada",
        "ZCOOL XiaoWei",
        "Manjari",
        "Overpass Mono",
        "Asap",
        "Megrim",
        "Share Tech Mono",
        "Allerta",
        "Patrick Hand SC",
        "Bilbo Swash Caps",
        "Italianno",
        "Fanwood Text",
        "Miriam Libre",
        "Brawler",
        "Martel Sans",
        "Satisfy",
        "Caveat",
        "Forum",
        "Happy Monkey",
        "Montez",
        "Mukta Vaani",
        "Signika",
        "Sansita",
        "Lemonada",
        "Ma Shan Zheng",
        "Prompt",
        "Alfa Slab One",
        "Archivo Narrow",
        "Syncopate",
        "Days One",
        "Give You Glory",
        "Questrial",
        "DM Sans",
        "Gaegu",
        "Nixie One",
        "Tajawal",
        "Abril Fatface",
        "Coming Soon",
        "ABeeZee",
        "Pangolin",
        "Crete Round",
        "Faster One",
        "Suranna",
        "Federo",
        "Sniglet",
        "Orienta",
        "Share",
        "Alegreya SC",
        "IBM Plex Mono",
        "Monda",
        "Over the Rainbow",
        "Courgette",
        "Share Tech",
        "Thasadith",
        "Biryani",
        "Carrois Gothic SC",
        "Arizonia",
        "Averia Sans Libre",
        "Kadwa",
        "Frank Ruhl Libre",
        "Literata",
        "Vampiro One",
        "Francois One",
        "Rajdhani",
        "Sen",
        "Lily Script One",
        "Lustria",
        "Tinos",
        "Amita",
        "Cantora One",
        "Arya",
        "Grand Hotel",
        "Kaushan Script",
        "Archivo Black",
        "Coiny",
        "Halant",
        "Big Shoulders Display",
        "M PLUS Rounded 1c",
        "Sarabun",
        "Slabo 13px",
        "Carrois Gothic",
        "Carter One",
        "Mina",
        "Alegreya",
        "Elsie",
        "Noto Serif JP",
        "Rosario",
        "Almendra",
        "Gilda Display",
        "Righteous",
        "Saira Condensed",
        "Carme",
        "Calligraffitti",
        "Padauk",
        "Port Lligat Slab",
        "Cardo",
        "Hachi Maru Pop",
        "Oxygen Mono",
        "Hanuman",
        "Nanum Myeongjo",
        "Ruslan Display",
        "Andada",
        "Kumar One",
        "Cuprum",
        "Emilys Candy",
        "League Script",
        "Blinker",
        "Cantarell",
        "Eater",
        "Creepster",
        "Pathway Gothic One",
        "B612",
        "Gamja Flower",
        "M PLUS 1p",
        "Amiri",
        "Didact Gothic",
        "IM Fell English",
        "Sonsie One",
        "Frijole",
        "Gloria Hallelujah",
        "Chelsea Market",
        "Belgrano",
        "Tomorrow",
        "Homenaje",
        "Ruluko",
        "Aguafina Script",
        "Elsie Swash Caps",
        "Prata",
        "Baumans",
        "Chathura",
        "Rokkitt",
        "BioRhyme",
        "News Cycle",
        "Saira Stencil One",
        "Akronim",
        "Oleo Script Swash Caps",
        "Passion One",
        "Qwigley",
        "Stylish",
        "Londrina Solid",
        "Proza Libre",
        "Ultra",
        "Anaheim",
        "Old Standard TT",
        "Hind Guntur",
        "Pirata One",
        "Ubuntu Condensed",
        "Chivo",
        "Esteban",
        "Cambo",
        "Orbitron",
        "Bungee Shade",
        "Miniver",
        "Montserrat Alternates",
        "Libre Caslon Display",
        "Playfair Display SC",
        "Unkempt",
        "Modern Antiqua",
        "Yantramanav",
        "Josefin Slab",
        "Rasa",
        "Expletus Sans",
        "Ramabhadra",
        "Crafty Girls",
        "Oldenburg",
        "Paytone One",
        "Be Vietnam",
        "Quattrocento",
        "Caesar Dressing",
        "Squada One",
        "Timmana",
        "IM Fell DW Pica",
        "Siemreap",
        "Yellowtail",
        "Londrina Shadow",
        "Meera Inimai",
        "Poiret One",
        "Vollkorn SC",
        "Dr Sugiyama",
        "Wendy One",
        "Metamorphous",
        "Strait",
        "Mako",
        "Delius Swash Caps",
        "Philosopher",
        "Farsan",
        "Baloo Thambi 2",
        "Imprima",
        "Sigmar One",
        "Amarante",
        "Irish Grover",
        "Press Start 2P",
        "IM Fell English SC",
        "Neuton",
        "Russo One",
        "Supermercado One",
        "Baloo 2",
        "Liu Jian Mao Cao",
        "Nova Square",
        "Fira Sans Extra Condensed",
        "Fraunces",
        "Nova Flat",
        "Butcherman",
        "Flamenco",
        "DM Serif Text",
        "Geostar Fill",
        "Port Lligat Sans",
        "Manuale",
        "Spectral",
        "Warnes",
        "Advent Pro",
        "Cormorant Upright",
        "Manrope",
        "Viaoda Libre",
        "Dynalight",
        "DM Mono",
        "Concert One",
        "Peralta",
        "Cherry Swash",
        "Volkhov",
        "Karma",
        "Macondo Swash Caps",
        "Asap Condensed",
        "Cormorant",
        "Big Shoulders Text",
        "Noto Serif TC",
        "UnifrakturCook",
        "Imbue",
        "Monoton",
        "Delius Unicase",
        "Shanti",
        "Wire One",
        "Alice",
        "Kite One",
        "Stalemate",
        "Gudea",
        "Lovers Quarrel",
        "Ranga",
        "Unica One",
        "Merienda",
        "Changa",
        "Homemade Apple",
        "Kumbh Sans",
        "Encode Sans Semi Expanded",
        "Neucha",
        "Angkor",
        "Jost",
        "Commissioner",
        "Khand",
        "Mogra",
        "Pontano Sans",
        "Simonetta",
        "Gentium Basic",
        "Overlock SC",
        "Buda",
        "Rock Salt",
        "Ropa Sans",
        "Baloo Bhai 2",
        "Viga",
        "Margarine",
        "JetBrains Mono",
        "Space Mono",
        "Condiment",
        "Mitr",
        "BenchNine",
        "Sancreek",
        "Nokora",
        "Palanquin",
        "Offside",
        "Tangerine",
        "Donegal One",
        "Jura",
        "Metal",
        "Armata",
        "Tulpen One",
        "Amaranth",
        "Solway",
        "Iceberg",
        "Eagle Lake",
        "Saira Semi Condensed",
        "Smythe",
        "Yeseva One",
        "Allura",
        "Atomic Age",
        "Ruthie",
        "Cute Font",
        "Jomolhari",
        "Unna",
        "Linden Hill",
        "Suez One",
        "Sahitya",
        "Trykker",
        "Audiowide",
        "Public Sans",
        "Ribeye Marrow",
        "Ewert",
        "Khula",
        "Devonshire",
        "Gochi Hand",
        "Goblin One",
        "Sawarabi Gothic",
        "Bad Script",
        "Bokor",
        "Adamina",
        "Galdeano",
        "Alef",
        "Jim Nightshade",
        "Hanalei",
        "Tenor Sans",
        "Beth Ellen",
        "Nanum Pen Script",
        "Allan",
        "Plaster",
        "Markazi Text",
        "Miss Fajardose",
        "Libre Barcode 128 Text",
        "Noto Serif SC",
        "Gupter",
        "Shadows Into Light Two",
        "Fascinate Inline",
        "Ubuntu Mono",
        "Gruppo",
        "Stalinist One",
        "Bai Jamjuree",
        "Erica One",
        "Saira",
        "Gidugu",
        "Mali",
        "Zhi Mang Xing",
        "Itim",
        "Nova Script",
        "Bungee Hairline",
        "Signika Negative",
        "Electrolize",
        "Lacquer",
        "Combo",
        "Nothing You Could Do",
        "Fascinate",
        "Fruktur",
        "Londrina Sketch",
        "Big Shoulders Inline Text",
        "Cantata One",
        "Lexend Peta",
        "Nanum Gothic Coding",
        "Gothic A1",
        "Trispace",
        "Big Shoulders Inline Display",
        "Faustina",
        "Quantico",
        "Abhaya Libre",
        "Syne Tactile",
        "Encode Sans Condensed",
        "Libre Barcode EAN13 Text",
        "Oleo Script",
        "Gelasio",
        "Lateef",
        "Mr Dafoe",
        "Arsenal",
        "Cousine",
        "Sarala",
        "Glegoo",
        "Baloo Chettan 2",
        "PT Mono",
        "Fira Mono",
        "El Messiri",
        "Rancho",
        "Average",
        "Boogaloo",
        "Cutive Mono",
        "Noto Serif KR",
        "Krub",
        "Jaldi",
        "Kreon",
        "Lilita One",
        "Capriola",
        "Marcellus",
        "Niconne",
        "Yrsa",
        "Enriqueta",
        "Alegreya Sans SC",
        "Fredericka the Great",
        "Saira Extra Condensed",
        "Caveat Brush",
        "Reenie Beanie",
        "Aldrich",
        "Just Another Hand",
        "Alex Brush",
        "Michroma",
        "Anonymous Pro",
        "Laila",
        "Overlock",
        "Lexend Deca",
        "Rufina",
        "Rubik Mono One",
        "Kameron",
        "Candal",
        "Bevan",
        "Sintony",
        "Six Caps",
        "Berkshire Swash",
        "Red Hat Text",
        "Holtwood One SC",
        "Norican",
        "Caudex",
        "Bentham",
        "Alata",
        "Shrikhand",
        "Telex",
        "Nobile",
        "Scada",
        "Kurale",
        "Reem Kufi",
        "Eczar",
        "Leckerli One",
        "Arima Madurai",
        "Allerta Stencil",
        "Bowlby One SC",
        "Fauna One",
        "Molengo",
        "Cinzel Decorative",
        "Yesteryear",
        "Racing Sans One",
        "Sofia",
        "IM Fell Double Pica",
        "Courier Prime",
        "Herr Von Muellerhoff",
        "Marcellus SC",
        "Kosugi Maru",
        "Rochester",
        "Chewy",
        "Mrs Saint Delafield",
        "Titan One",
        "Judson",
        "Do Hyeon",
        "Contrail One",
        "Arbutus Slab",
        "Oranienbaum",
        "Coda Caption",
        "Copse",
        "Graduate",
        "Rye",
        "Wallpoet",
        "Niramit",
        "Averia Serif Libre",
        "IBM Plex Sans Condensed",
        "Delius",
        "Palanquin Dark",
        "Cabin Sketch",
        "Scheherazade",
        "Marmelad",
        "Kristi",
        "Athiti",
        "Ovo",
        "Alatsi",
        "Rambla",
        "Red Rose",
        "Nanum Brush Script",
        "Chonburi",
        "GFS Didot",
        "Trocchi",
        "Merienda One",
        "Maitree",
        "Voltaire",
        "Love Ya Like A Sister",
        "Jockey One",
        "Cedarville Cursive",
        "Metrophobic",
        "Cambay",
        "Kelly Slab",
        "Amethysta",
        "Bungee Inline",
        "Cutive",
        "Baloo Da 2",
        "Aladin",
        "Duru Sans",
        "Sedgwick Ave",
        "Lemon",
        "Pompiere",
        "Chau Philomene One",
        "Zeyada",
        "Monsieur La Doulaise",
        "Raleway Dots",
        "UnifrakturMaguntia",
        "Harmattan",
        "Darker Grotesque",
        "Krona One",
        "Belleza",
        "Libre Caslon Text",
        "La Belle Aurore",
        "Tenali Ramakrishna",
        "Gugi",
        "Marvel",
        "Comic Neue",
        "Doppio One",
        "K2D",
        "Alike",
        "Magra",
        "Turret Road",
        "Buenard",
        "Changa One",
        "Farro",
        "Radley",
        "Gabriela",
        "Pattaya",
        "Average Sans",
        "Freckle Face",
        "Jua",
        "Cormorant Infant",
        "Gurajada",
        "Mandali",
        "Corben",
        "David Libre",
        "Sue Ellen Francisco",
        "Balthazar",
        "Mr De Haviland",
        "Rammetto One",
        "Podkova",
        "Epilogue",
        "Finger Paint",
        "Bowlby One",
        "Grandstander",
        "Limelight",
        "Pavanam",
        "Petit Formal Script",
        "Rouge Script",
        "Seaweed Script",
        "Amiko",
        "Baloo Tamma 2",
        "Denk One",
        "Cormorant SC",
        "Rozha One",
        "McLaren",
        "Oregano",
        "Skranji",
        "Bellefair",
        "Yusei Magic",
        "Quando",
        "Waiting for the Sunrise",
        "Rakkas",
        "Libre Barcode 39",
        "Numans",
        "Mountains of Christmas",
        "Stardos Stencil",
        "Hepta Slab",
        "Fondamento",
        "Aref Ruqaa",
        "Sarpanch",
        "Vast Shadow",
        "Grenze Gotisch",
        "Inder",
        "Kosugi",
        "Antic Didone",
        "Fira Code",
        "Ledger",
        "Baskervville",
        "Geo",
        "Mouse Memoirs",
        "Montaga",
        "NTR",
        "Lekton",
        "Encode Sans Expanded",
        "Mansalva",
        "Vesper Libre",
        "Nova Mono",
        "Euphoria Script",
        "Tienne",
        "Life Savers",
        "Sora",
        "Livvic",
        "Alike Angular",
        "Short Stack",
        "B612 Mono",
        "Shojumaru",
        "Spectral SC",
        "Caladea",
        "Ranchers",
        "Englebert",
        "Sail",
        "Bubbler One",
        "Convergence",
        "Asul",
        "Mate",
        "Salsa",
        "Walter Turncoat",
        "Montserrat Subrayada",
        "Atma",
        "Inknut Antiqua",
        "Notable",
        "Voces",
        "Codystar",
        "Gafata",
        "Scope One",
        "Puritan",
        "Artifika",
        "ZCOOL QingKe HuangYou",
        "Gravitas One",
        "Hi Melody",
        "Headland One",
        "Fresca",
        "Calistoga",
        "Cherry Cream Soda",
        "The Girl Next Door",
        "Andika",
        "Slackey",
        "Poller One",
        "Dokdo",
        "Fjord One",
        "Prosto One",
        "Fontdiner Swanky",
        "Mukta Mahee",
        "Ramaraja",
        "Engagement",
        "Trade Winds",
        "Bilbo",
        "Koulen",
        "Lexend Zetta",
        "Ribeye",
        "Sura",
        "Clicker Script",
        "Italiana",
        "Nova Round",
        "Henny Penny",
        "Kranky",
        "Iceland",
        "East Sea Dokdo",
        "Galada",
        "Paprika",
        "Quintessential",
        "Germania One",
        "Meddon",
        "Yeon Sung",
        "Mate SC",
        "Chicle",
        "Julee",
        "Katibeh",
        "Nosifer",
        "Encode Sans Semi Condensed",
        "Text Me One",
        "Baloo Paaji 2",
        "Yatra One",
        "Milonga",
        "Libre Barcode 39 Extended Text",
        "Rosarivo",
        "Chango",
        "Tauri",
        "Kodchasan",
        "Swanky and Moo Moo",
        "IM Fell French Canon",
        "Baloo Bhaina 2",
        "Rationale",
        "Underdog",
        "Fenix",
        "Crushed",
        "Petrona",
        "Averia Gruesa Libre",
        "Cagliostro",
        "Bayon",
        "Loved by the King",
        "Piazzolla",
        "Mystery Quest",
        "Sumana",
        "Uncial Antiqua",
        "Hanalei Fill",
        "Lakki Reddy",
        "Goldman",
        "Inria Serif",
        "Zilla Slab Highlight",
        "Dekko",
        "Marko One",
        "KoHo",
        "Major Mono Display",
        "Bigelow Rules",
        "Tillana",
        "Jomhuria",
        "Stint Ultra Condensed",
        "Asar",
        "Sarina",
        "Spicy Rice",
        "Bellota",
        "Stint Ultra Expanded",
        "Londrina Outline",
        "Gorditas",
        "Potta One",
        "Croissant One",
        "Lexend Exa",
        "Rowdies",
        "Redressed",
        "Just Me Again Down Here",
        "Monofett",
        "Original Surfer",
        "Rum Raisin",
        "Chela One",
        "Kavoon",
        "Joti One",
        "IM Fell DW Pica SC",
        "Ibarra Real Nova",
        "Maiden Orange",
        "Cormorant Unicase",
        "Gayathri",
        "Piedra",
        "Griffy",
        "Inika",
        "Medula One",
        "Srisakdi",
        "Vibur",
        "IM Fell French Canon SC",
        "Rhodium Libre",
        "Sree Krushnadevaraya",
        "Space Grotesk",
        "Libre Barcode 39 Text",
        "Glass Antiqua",
        "Spirax",
        "Meie Script",
        "Wellfleet",
        "Jacques Francois Shadow",
        "Diplomata",
        "Habibi",
        "Lancelot",
        "Content",
        "Oxanium",
        "Metal Mania",
        "Odor Mean Chey",
        "Romanesco",
        "Princess Sofia",
        "Jolly Lodger",
        "Chilanka",
        "Sansita Swashed",
        "Libre Barcode 39 Extended",
        "Baloo Tammudu 2",
        "Almendra SC",
        "Varta",
        "Poor Story",
        "Kavivanar",
        "Flavors",
        "New Rocker",
        "Snippet",
        "Bodoni Moda",
        "Recursive",
        "Bellota Text",
        "Texturina",
        "Dangrek",
        "Sulphur Point",
        "Mrs Sheppards",
        "Keania One",
        "Purple Purse",
        "Khmer",
        "Risque",
        "Emblema One",
        "Mr Bedfort",
        "Freehand",
        "Peddana",
        "Galindo",
        "Syne",
        "Almendra Display",
        "Bungee Outline",
        "Kotta One",
        "Nova Slim",
        "Smokum",
        "Kumar One Outline",
        "Suwannaphum",
        "IM Fell Great Primer SC",
        "Prociono",
        "Vibes",
        "Bahiana",
        "Sedgwick Ave Display",
        "Castoro",
        "Modak",
        "IM Fell Double Pica SC",
        "Trochut",
        "Jacques Francois",
        "Barriecito",
        "Black And White Picture",
        "Stoke",
        "Inria Sans",
        "Nerko One",
        "Girassol",
        "Ruge Boogie",
        "Andika New Basic",
        "Taprom",
        "Macondo",
        "Song Myung",
        "Big Shoulders Stencil Text",
        "Ravi Prakash",
        "Federant",
        "Unlock",
        "Chenla",
        "ZCOOL KuaiLe",
        "Butterfly Kids",
        "Libre Barcode 128",
        "Bonbon",
        "Grenze",
        "Lexend Mega",
        "Astloch",
        "Della Respira",
        "Kufam",
        "Charmonman",
        "Moulpali",
        "Nova Cut",
        "Kirang Haerang",
        "Aubrey",
        "Nova Oval",
        "IM Fell Great Primer",
        "Miltonian Tattoo",
        "Dorsa",
        "Sevillana",
        "Passero One",
        "Barrio",
        "Langar",
        "Fasthand",
        "MuseoModerno",
        "Junge",
        "Long Cang",
        "Suravaram",
        "Fahkwang",
        "Kdam Thmor",
        "Geostar",
        "Dhurjati",
        "Kenia",
        "BioRhyme Expanded",
        "Big Shoulders Stencil Display",
        "Autour One",
        "Bahianita",
        "Xanh Mono",
        "Moul",
        "Kulim Park",
        "Arbutus",
        "Seymour One",
        "Felipa",
        "Molle",
        "Bigshot One",
        "Sirin Stencil",
        "Asset",
        "Diplomata SC",
        "Kantumruy",
        "Revalia",
        "MedievalSharp",
        "Gotu",
        "GFS Neohellenic",
        "Snowburst One",
        "Sunshiney",
        "Lexend Giga",
        "Preahvihear",
        "Odibee Sans",
        "Sofadi One",
        "Miltonian",
        "Lexend Tera",
        "Single Day",
        "Syne Mono"
      ],
      uploadedFonts: [],
      supportedFontFormats: [
        // {'fileExtenstion': '.JFPROJ', 'fileType': 'JSON Font Project File', 'fileFormat': ''},
        // {'fileExtenstion': '.PFA', 'fileType': 'Printer Font ASCII File', 'fileFormat': ''},
        {
          fileExtenstion: ".TTF",
          fileType: "TrueType Font",
          fileFormat: "truetype"
        },
        {
          fileExtenstion: ".WOFF",
          fileType: "Web Open Font Format File",
          fileFormat: "woff"
        },
        // {'fileExtenstion': '.FNT', 'fileType': 'Windows Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.FOT', 'fileType': 'Font Resource File', 'fileFormat': ''},
        // {'fileExtenstion': '.SFD', 'fileType': 'Spline Font Database File', 'fileFormat': ''},
        // {'fileExtenstion': '.PFB', 'fileType': 'Printer Font Binary File', 'fileFormat': ''},
        // {'fileExtenstion': '.OTF', 'fileType': 'OpenType Font', 'fileFormat': ''},
        // {'fileExtenstion': '.GXF', 'fileType': 'General CADD Pro Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.ODTTF', 'fileType': 'Obfuscated OpenType Font', 'fileFormat': ''},
        // {'fileExtenstion': '.VLW', 'fileType': 'Processing Font File', 'fileFormat': ''},
        {
          fileExtenstion: ".WOFF2",
          fileType: "Web Open Font Format 2.0 File",
          fileFormat: "woff2"
        }
        // {'fileExtenstion': '.FON', 'fileType': 'Windows Font Library', 'fileFormat': ''},
        // {'fileExtenstion': '.ETX', 'fileType': 'TeX Font Encoding File', 'fileFormat': ''},
        // {'fileExtenstion': '.PFM', 'fileType': 'Printer Font Metrics File', 'fileFormat': ''},
        // {'fileExtenstion': '.CHR', 'fileType': 'Borland Character Set File', 'fileFormat': ''},
        // {'fileExtenstion': '.VFB', 'fileType': 'FontLab Studio Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.BDF', 'fileType': 'Glyph Bitmap Distribution Format', 'fileFormat': ''},
        // {'fileExtenstion': '.PMT', 'fileType': 'PageMaker Template File', 'fileFormat': ''},
        // {'fileExtenstion': '.AMFM', 'fileType': 'Adobe Multiple Font Metrics File', 'fileFormat': ''},
        // {'fileExtenstion': '.MF', 'fileType': 'METAFONT File', 'fileFormat': ''},
        // {'fileExtenstion': '.COMPOSITEFONT', 'fileType': 'Windows Composite Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.ABF', 'fileType': 'Adobe Binary Screen Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.GDR', 'fileType': 'Symbian OS Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.GF', 'fileType': 'METAFONT Bitmap File', 'fileFormat': ''},
        // {'fileExtenstion': '.TTC', 'fileType': 'TrueType Font Collection', 'fileFormat': ''}, // Need to extract each font from the font collection in order to use on the web
        // {'fileExtenstion': '.VNF', 'fileType': 'Vision Numeric Font', 'fileFormat': ''},
        // {'fileExtenstion': '.ACFM', 'fileType': 'Adobe Composite Font Metrics File', 'fileFormat': ''},
        // {'fileExtenstion': '.MXF', 'fileType': 'Maxis Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.PCF', 'fileType': 'PaintCAD Font', 'fileFormat': ''},
        // {'fileExtenstion': '.SFP', 'fileType': 'Soft Font Printer File', 'fileFormat': ''},
        // {'fileExtenstion': '.PFR', 'fileType': 'Portable Font Resource File', 'fileFormat': ''},
        // {'fileExtenstion': '.XFN', 'fileType': 'Ventura Printer Font', 'fileFormat': ''},
        // {'fileExtenstion': '.GLIF', 'fileType': 'Glyph Interchange Format File', 'fileFormat': ''},
        // {'fileExtenstion': '.TFM', 'fileType': 'TeX Font Metric File', 'fileFormat': ''},
        // {'fileExtenstion': '.AFM', 'fileType': 'Adobe Font Metrics File', 'fileFormat': ''},
        // {'fileExtenstion': '.DFONT', 'fileType': 'Mac OS X Data Fork Font', 'fileFormat': ''},
        // {'fileExtenstion': '.TTE', 'fileType': 'Private Character Editor File', 'fileFormat': ''},
        // {'fileExtenstion': '.EOT', 'fileType': 'Embedded OpenType Font', 'fileFormat': ''},
        // {'fileExtenstion': '.XFT', 'fileType': 'ChiWriter Printer Font', 'fileFormat': ''},
        // {'fileExtenstion': '.PK', 'fileType': 'Packed METAFONT File', 'fileFormat': ''},
        // {'fileExtenstion': '.FFIL', 'fileType': 'Mac Font Suitcase', 'fileFormat': ''},
        // {'fileExtenstion': '.SUIT', 'fileType': 'Macintosh Font Suitcase', 'fileFormat': ''},
        // {'fileExtenstion': '.PF2', 'fileType': 'GRUB Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.EUF', 'fileType': 'Private Character Editor File', 'fileFormat': ''},
        // {'fileExtenstion': '.NFTR', 'fileType': 'Nintendo DS Font Type File', 'fileFormat': ''},
        // {'fileExtenstion': '.TXF', 'fileType': 'Celestia Font Texture File', 'fileFormat': ''},
        // {'fileExtenstion': '.MCF', 'fileType': 'Watchtower Library Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.CHA', 'fileType': 'Character Layout File', 'fileFormat': ''},
        // {'fileExtenstion': '.LWFN', 'fileType': 'Adobe Type 1 Mac Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.UFO', 'fileType': 'Unified Font Object File', 'fileFormat': ''},
        // {'fileExtenstion': '.T65', 'fileType': 'PageMaker Template File', 'fileFormat': ''},
        // {'fileExtenstion': '.YTF', 'fileType': 'Google Picasa Font Cache', 'fileFormat': ''},
        // {'fileExtenstion': '.F3F', 'fileType': 'Crazy Machines Font File', 'fileFormat': ''},
        // {'fileExtenstion': '.FEA', 'fileType': 'AFDKO Feature Definitions File', 'fileFormat': ''},
      ],
      isIntersecting: false,
      editTextDialog: false,
      filterFontDialog: false,
      uploadFontDialog: false,
      fontSourceFilters: [],
      fontTypeFilters: [],
      fontSelectionFilters: [],
      fontSearchFilters: [],
      fontTagFilters: [],
      currentFiles: []
    };
  },
  computed: {
    allFonts() {
      return [...this.googleFonts, ...this.uploadedFonts];
    },
    displayedFonts() {
      var fonts = this.allFonts;
      if (this.fontSourceFilters.length > 0) {
        fonts = fonts.filter(el => this.fontSourceFilters.includes(el));
      }
      if (this.fontTypeFilters.length > 0) {
        fonts = fonts.filter(el => this.fontTypeFilters.includes(el));
      }
      if (this.fontSelectionFilters.length > 0) {
        fonts = fonts.filter(el => this.fontSelectionFilters.includes(el));
      }
      if (this.fontSearchFilters.length > 0) {
        fonts = fonts.filter(el => this.fontSearchFilters.includes(el));
      }
      if (this.fontTagFilters.length > 0) {
        fonts = fonts.filter(el => this.fontTagFilters.includes(el));
      }
      return fonts;
    },
    acceptedFileExtensions() {
      return this.supportedFontFormats.map(item => item.fileExtenstion);
    },
    ...mapGetters([]),
    ...mapState(["fonts"])
  },
  async mounted() {
    // fetch(
    //   "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyD2XlgdSV2aMaFApwYwRZPGty_5PDSUaZA"
    // )
    //   .then(r => r.json())
    //   .then(fontsObject => {
    //     for (const font of fontsObject.items) {
    //       let fontFamily = font.family;
    //       this.googleFonts.push(fontFamily);
    //     }
    //     let chunkedFonts = this.chunk(this.googleFonts, 12); // this.chunk(fontsObject.items, 12);
    //     let importStatements = chunkedFonts
    //       .map(chunkedFontsArr => {
    //         return `@import "https://fonts.googleapis.com/css?family=${chunkedFontsArr
    //           .map(f => f.family)
    //           .join("|")}"`;
    //       })
    //       .join(";");
    //     let fontsStyle = document.createElement("style");
    //     fontsStyle = "text/css";
    //     fontsStyle.innerHTML = importStatements;
    //     document.getElementsByTagName("head")[0].appendChild(fontsStyle);
    //   });
    // KEEP BELOW
    let chunkedFonts = this.chunk(this.googleFonts, 12); // this.chunk(fontsObject.items, 12);
    let importStatements = chunkedFonts
      .map(chunkedFontsArr => {
        return `@import "https://fonts.googleapis.com/css?family=${chunkedFontsArr
          .map(f => f)
          .join("|")}"`;
      })
      .join(";");
    let fontsStyle = document.createElement("style");
    // fontsStyle.type = "text/css";
    fontsStyle.innerHTML = importStatements;
    document.getElementsByTagName("head")[0].appendChild(fontsStyle);
    // KEEP ABOVE
    // var values = [],
    //   keys = Object.keys(localStorage),
    //   i = keys.length;
    // while (i--) {
    //   values.push(localStorage.getItem(keys[i]));
    // }
    // // base64 to buffer
    // function base64ToBufferAsync(base64) {
    //   // var dataUrl = "data:application/octet-binary;base64," + base64;
    //   // return fetch(dataUrl)
    //   fetch(base64)
    //     .then(res => res.arrayBuffer())
    //     .then(buffer => {
    //       console.log("base64 to buffer: " + new Uint8Array(buffer));
    //       return new Uint8Array(buffer);
    //     });
    // }
    // // buffer to base64
    // function bufferToBase64Async(buffer) {
    //   var blob = new Blob([buffer], { type: "application/octet-binary" });
    //   console.log("buffer to blob:" + blob);
    //   var fileReader = new FileReader();
    //   fileReader.onload = function() {
    //     var dataUrl = fileReader.result;
    //     console.log("blob to dataUrl: " + dataUrl);
    //     var base64 = dataUrl.substr(dataUrl.indexOf(",") + 1);
    //     console.log("dataUrl to base64: " + base64);
    //   };
    //   fileReader.readAsDataURL(blob);
    // }
    // Object.keys(localStorage).forEach(storedItemKey => {
    //   if (storedItemKey.includes("uploadedFonts/")) {
    //     var encodedStoredFont = localStorage.getItem(storedItemKey);
    //     var decodedStoredFont = atob(encodedStoredFont);
    //     var font = JSON.parse(decodedStoredFont);
    //     const byteCharacters = atob(font.encodedData.substr(37));
    //     const byteNumbers = new Array(byteCharacters.length);
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //       byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     const newBlob = new Blob([byteArray], {
    //       type: "data:application/octet-stream"
    //     });
    //     newBlob.lastModifiedDate = font.fileLastModifiedDate;
    //     newBlob.name = font.fullFileName;
    //     var newFile = new File([newBlob], font.fullFileName);
    //     console.log(newFile);
    //     const fontURL = window.webkitURL.createObjectURL(newBlob);
    //     let fontsStyle = document.createElement("style");
    //     // fontsStyle.type = "text/css";
    //     fontsStyle.innerHTML =
    //       "@font-face {font-family: " +
    //       font.fileName +
    //       "; src: url(" +
    //       fontURL +
    //       ");}";
    //     document.getElementsByTagName("head")[0].appendChild(fontsStyle);
    //     this.uploadedFonts.push(font.fileName);
    //   }
    // });
    // this.$store.dispatch("getFonts");
    // this.allFonts.forEach(font => {
    //   console.log("creating font ", font);
    //   this.$store
    //     .dispatch("createFont", font)
    //     .then(console.log("created font", font));
    // });
  },
  methods: {
    onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].intersectionRatio < 0.5;
      // console.log(observer);
    },
    chunk(ar, size) {
      let buffer = [];
      return ar.reduce((acc, item, i) => {
        let isLast = i === ar.length - 1;

        if (buffer.length === size) {
          let theChunk = [...buffer];
          buffer = [item];
          return [...acc, theChunk];
        } else {
          buffer.push(item);
          if (isLast) {
            return [...acc, buffer];
          } else {
            return acc;
          }
        }
      }, []);
    },
    removeFilters() {
      this.fontSourceFilters = [];
      this.fontTypeFilters = [];
      this.fontSelectionFilters = [];
      this.fontSearchFilters = [];
      this.fontTagFilters = [];
    }
    // async uploadFonts(files) {
    //   // console.log(typeof(uploadedFontFiles))
    //   // console.log(uploadedFontFiles);
    //   // let fontsStyle = document.createElement("style");
    //   // // fontsStyle.type = "text/css";
    //   // const reader = new FileReader();
    //   // // const fileContents = reader.readAsText(uploadedFontFiles);
    //   // const fileContents = reader.readAsText(uploadedFontFiles.target.files[0]);
    //   // console.log(typeof(fileContents))
    //   // console.log(fileContents)
    //   // fontsStyle.innerHTML = fileContents;
    //   // document.getElementsByTagName("head")[0].appendChild(fontsStyle);
    //   // this.uploadedFonts.push(uploadedFontFiles.name)
    //   // console.log('done!')
    //   console.log("starting...");
    //   var uploadTimestamp = new Date();
    //   function getBase64(uploadfile) {
    //     return new Promise(function(resolve) {
    //       var reader = new FileReader();
    //       reader.onloadend = function() {
    //         resolve(reader);
    //       };
    //       reader.readAsDataURL(uploadfile);
    //     });
    //   }
    //   files.forEach(async file => {
    //     console.log(file);
    //     var readFile = await getBase64(file).then(function(reader) {
    //       return {
    //         // originalFile: file,
    //         encodedData: reader.result,
    //         uploadTime: uploadTimestamp,
    //         // 'fullFilePath': '' // TODO: Get full file path of uploaded file
    //         // 'folderPath': '' // TODO: Get folder file path of uploaded file
    //         fullFileName: file.name,
    //         fileExtension:
    //           "." + file.name.split(".")[file.name.split(".").length - 1],
    //         fileName: file.name.substring(
    //           0,
    //           file.name.length -
    //             ("." + file.name.split(".")[file.name.split(".").length - 1])
    //               .length
    //         ),
    //         fileLastModifiedDate: file.lastModifiedDate
    //       };
    //     });
    //     console.log(readFile);
    //     const fontURL = window.URL.createObjectURL(file);
    //     let fontsStyle = document.createElement("style");
    //     // fontsStyle.type = "text/css";
    //     fontsStyle.innerHTML =
    //       "@font-face {font-family: " +
    //       readFile.fileName +
    //       "; src: url(" +
    //       fontURL +
    //       ");}";
    //     document.getElementsByTagName("head")[0].appendChild(fontsStyle);

    //     localStorage.setItem(
    //       "uploadedFonts/" + readFile.fileName,
    //       Buffer.from(JSON.stringify(readFile)).toString("base64")
    //     );
    //     this.uploadedFonts.push(readFile.fileName);
    //   });

    //   // let fontsStyle = document.createElement("style");
    //   // // fontsStyle.type = "text/css";
    //   // fontsStyle.innerHTML = fileContents;
    //   // document.getElementsByTagName("head")[0].appendChild(fontsStyle);

    //   // console.log("done!");
    // }
  }
};
</script>
