<template>
  <v-app>
    <v-app-bar app clipped-left>
      <v-toolbar-title>Font Madness</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="false" text>Login</v-btn>

      <v-menu v-if="false" offset-y offset-x rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on">
            <v-img
              src="https://randomuser.me/api/portraits/women/85.jpg"
            ></v-img>
          </v-avatar>
          <!-- <v-avatar color="red" v-bind="attrs" v-on="on">
            <span class="headline">JD</span>
          </v-avatar> -->
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                src="https://randomuser.me/api/portraits/women/85.jpg"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Jane Doe
              </v-list-item-title>
              <v-list-item-subtitle>jane.doe@domain.com</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>
                mdi-cog
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu v-if="false" offset-y rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>
                mdi-gift
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Donate</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>
                mdi-message-alert
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Feedback</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>
                mdi-help-circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>
